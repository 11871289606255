import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';
import type { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';

import {
  GetAllPortfolios,
  GetCommunityFundsCount,
  GetDonationsCount,
  GetFeaturedActivity,
  GetFeaturedFunds,
  GetFeaturedOrgs,
  GetOnboardedCounts,
  GetTotalDonations,
  GetTotalGrants,
} from '@endaoment-frontend/api';
import { TIME_ONE_MINUTE_IN_SECONDS } from '@endaoment-frontend/constants';
import { makeDehydratedQueriesString } from '@endaoment-frontend/data-fetching';
import { useOpenDonationWizard } from '@endaoment-frontend/donation-wizard';
import { useOpenFundWizard } from '@endaoment-frontend/fund-wizard';
import { routes } from '@endaoment-frontend/routes';
import {
  DonateDirectIcon,
  ExploreActionIcon,
  LearnIcon,
  MigrateIcon,
  NewFundIcon,
  SearchIcon,
} from '@endaoment-frontend/ui/icons';
import { ActionButton } from '@endaoment-frontend/ui/shared';
import { ExploreCarousels } from '@endaoment-frontend/ui/smart';

import { EINModal, useEinModal } from '../components/einModal';
import { DeployMapSection } from '../content/explore/deployMapSection';
import { Hero, PortfoliosSection, RecentActivity } from '../content/home';

import type { AppProps } from './_app.page';
import styles from './index.module.scss';

export const getStaticProps: GetStaticProps<AppProps> = async () => {
  const seo: AppProps['seo'] = {
    title: 'Endaoment - Welcome!',
  };
  try {
    return {
      props: {
        dehydratedState: await makeDehydratedQueriesString(
          GetFeaturedFunds.getDehydratedQueryArgs([]),
          GetFeaturedOrgs.getDehydratedQueryArgs([]),
          GetTotalDonations.getDehydratedQueryArgs([]),
          GetAllPortfolios.getDehydratedQueryArgs([]),
          GetOnboardedCounts.getDehydratedQueryArgs([]),
          GetCommunityFundsCount.getDehydratedQueryArgs([]),
          GetDonationsCount.getDehydratedQueryArgs([]),
          GetTotalGrants.getDehydratedQueryArgs([]),
          GetFeaturedActivity.getDehydratedQueryArgs([]),
        ),
        seo,
      },
      // ISR every 5 minutes
      revalidate: 5 * TIME_ONE_MINUTE_IN_SECONDS,
    };
  } catch {
    // On fetch errors, we still want to allow the build to continue, we will reattempt to fetch the data on the client
    return {
      props: {
        seo,
      },
      revalidate: 1,
    };
  }
};

const HomePage: NextPage = () => {
  const router = useRouter();

  const openDonationWizard = useOpenDonationWizard();
  const openFundWizard = useOpenFundWizard();
  const { open: openEinModal } = useEinModal();

  return (
    <>
      <Hero />
      <RecentActivity />
      <div className={styles['three-buttons']}>
        <div>
          <div>
            <ActionButton
              text='Start a Fund'
              subtext='Free to setup. Give now, get a receipt, and grant over time'
              onClick={() => openFundWizard({ initialMode: 'create' })}
              color='fund'
              spinny
              className={clsx(styles['action-button'], styles['action-button__spinny'])}>
              <span>
                <NewFundIcon width={30} />
              </span>
            </ActionButton>
            <ActionButton
              text='Migrate a DAF'
              subtext='Transfer a donor-advised fund from another provider to Endaoment'
              onClick={() => openFundWizard({ initialMode: 'migrate' })}
              color='violet'
              className={styles['action-button']}>
              <MigrateIcon width={28} />
            </ActionButton>
          </div>
          <div>
            <ActionButton
              text='Donate Now'
              subtext='Choose from over 1.8 million nonprofits across the globe'
              onClick={() => openDonationWizard()}
              color='org'
              className={styles['action-button']}>
              <DonateDirectIcon width={32} />
            </ActionButton>
          </div>
        </div>
      </div>

      <section className={styles['explore-section']}>
        <div className={styles['explore-title']}>
          <div>
            <h1>Explore</h1>
            <p>Search organizations across 150+ countries and 26 categories to find causes to support.</p>
          </div>
        </div>
        <DeployMapSection
          onClickCountry={entry => {
            router.push(
              routes.app.explore({
                countries: [entry.code],
              }),
            );
          }}
        />
      </section>
      <section>
        <div className={styles['explore-carousels']}>
          <div className={styles['explore-title']}>
            <div>
              <h1>Featured</h1>
              <p>
                Check out hand-picked nonprofits, or give to a community fund geared towards an issue area or community.
              </p>
            </div>
          </div>
          <div>
            <div>
              <ExploreCarousels />
            </div>
          </div>
        </div>
      </section>
      <div className={clsx(styles['three-buttons'], styles['three-buttons__bordered'])}>
        <div>
          <div>
            <ActionButton
              text='Explore'
              subtext='Discover our global network of nonprofit organizations'
              as={Link}
              href={routes.app.explore()}
              color='green'
              className={clsx(styles['action-button'], styles['action-button__spinny'])}
              spinny>
              <span>
                <ExploreActionIcon />
              </span>
            </ActionButton>
            <ActionButton
              text='Nonprofit Lookup'
              subtext='Accept crypto, cash, and stock donations at no cost'
              onClick={openEinModal}
              color='org'
              className={styles['action-button']}>
              <SearchIcon color='currentColor' width={22} />
            </ActionButton>
          </div>
          <div>
            <ActionButton
              text='Learn About Us'
              subtext='Discover how Endaoment can bring your nonprofit to the new web'
              as='a'
              target='_blank'
              href='https://docs.endaoment.org'
              color='red'
              className={styles['action-button']}>
              <LearnIcon color='currentColor' width={28} />
            </ActionButton>
          </div>
        </div>
      </div>
      <PortfoliosSection />

      <EINModal />
    </>
  );
};

export default HomePage;
