import { atom, useAtomValue } from 'jotai';
import { useAtomCallback } from 'jotai/utils';

import { Modal } from '@endaoment-frontend/ui/shared';
import { EINLookup } from '@endaoment-frontend/ui/smart';

import styles from './EINModal.module.scss';

const einOpenAtom = atom(false);

export const useEinModal = () => ({
  open: useAtomCallback((_get, set) => set(einOpenAtom, true)),
  close: useAtomCallback((_get, set) => set(einOpenAtom, false)),
  isOpen: useAtomValue(einOpenAtom),
});

export const EINModal = () => {
  const { isOpen, close } = useEinModal();

  return (
    <Modal isOpen={isOpen} onClose={close} showCloseButton={false} className={styles['modal']}>
      <EINLookup />
    </Modal>
  );
};
