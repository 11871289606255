import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { GetOrg } from '@endaoment-frontend/api';
import { isFetchError } from '@endaoment-frontend/data-fetching';
import { type EIN } from '@endaoment-frontend/types';
import { normalizeEin } from '@endaoment-frontend/utils';

export const useEINLookup = () => {
  const [isComplete, setIsComplete] = useState(false);
  const [display, setDisplay] = useState('');
  const { mutate, data, isError } = useMutation({
    mutationKey: [GetOrg.key],
    mutationFn: (ein: EIN) => GetOrg.execute(ein),
    retry: (c, e) => {
      if (isFetchError(e) && e.status === 404) return false;
      return c < 3;
    },
  });

  const lookup = (raw: string) => {
    if (!raw.match(/^[0-9-]{0,10}$/)) return;

    const rawWithoutDashes = raw.replace(/-/g, '');
    const newLookupVal =
      rawWithoutDashes.length > 2 ? rawWithoutDashes.slice(0, 2) + '-' + rawWithoutDashes.slice(2) : rawWithoutDashes;
    const isNowComplete = newLookupVal.length === 10;

    setIsComplete(isNowComplete);
    setDisplay(newLookupVal);

    if (isNowComplete) {
      mutate(normalizeEin(raw));
    }
  };

  return {
    org: isComplete ? data : undefined,
    failed: isComplete && isError,
    displayedEIN: display,
    lookup,
  };
};
