import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { routes } from '@endaoment-frontend/routes';
import { Button, Card } from '@endaoment-frontend/ui/shared';
import { formatPhysicalAddress } from '@endaoment-frontend/utils';

import styles from './EINLookup.module.scss';
import { useEINLookup } from './useEINLookup';

export const EINLookup = ({ shouldOpenTab = false, className }: { shouldOpenTab?: boolean; className?: string }) => {
  const { displayedEIN, lookup, org, failed } = useEINLookup();

  return (
    <Card className={clsx(styles['ein-lookup'], className)}>
      <AnimatePresence mode='wait' initial={false}>
        <motion.div
          className={styles['result']}
          key={org ? `${org.ein}-title` : 'no-result-title'}
          initial='hidden'
          animate='visible'
          exit='hidden'
          variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}>
          <h3>
            {org ? (
              <Link
                href={routes.app.org({ einOrId: org.id })}
                target={shouldOpenTab ? '_blank' : undefined}
                rel={shouldOpenTab ? 'noreferrer' : undefined}>
                <b>{org.name}</b>
              </Link>
            ) : (
              'Nonprofit Lookup'
            )}
          </h3>
          <p>
            {org ? (
              <span>{formatPhysicalAddress(org.address)}</span>
            ) : (
              'Claim your 501(c)(3) organization to receive donated funds'
            )}
          </p>
        </motion.div>
      </AnimatePresence>
      <div className={styles['actions']}>
        <input value={displayedEIN} onChange={e => lookup(e.currentTarget.value)} placeholder='12-3456789' />
        <div className={styles['actions__button-row']}>
          {org ? (
            <>
              {!org.claimed && (
                <Button
                  as={Link}
                  href={routes.app.orgClaim({ einOrId: org.ein ?? org.id })}
                  variation='org'
                  filled
                  target={shouldOpenTab ? '_blank' : undefined}
                  rel={shouldOpenTab ? 'noreferrer' : undefined}
                  className={styles['lookup-button']}>
                  Claim Org
                </Button>
              )}
              <Button
                as={Link}
                href={routes.app.org({ einOrId: org.id })}
                variation='org'
                filled
                target={shouldOpenTab ? '_blank' : undefined}
                rel={shouldOpenTab ? 'noreferrer' : undefined}
                className={styles['lookup-button']}>
                Go to Org
              </Button>
            </>
          ) : (
            <Button as='span' variation='org' className={styles['lookup-button']} float={false}>
              {displayedEIN.length > 2 && failed ? 'Invalid EIN' : 'Enter EIN'}
            </Button>
          )}
          <Button
            as='a'
            variation='fund'
            filled
            href={routes.app.explore()}
            target={shouldOpenTab ? '_blank' : undefined}
            rel={shouldOpenTab ? 'noreferrer' : undefined}
            className={styles['lookup-button']}>
            Search All
          </Button>
        </div>
      </div>
    </Card>
  );
};
