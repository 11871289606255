import { Link } from '@chakra-ui/next-js';
import type { As } from '@chakra-ui/react';
import clsx from 'clsx';
import Image from 'next/image';
import Tilt from 'react-parallax-tilt';
import { Swiper, SwiperSlide } from 'swiper/react';

import { GetPortfolioCategories } from '@endaoment-frontend/api';
import { useIsMobile } from '@endaoment-frontend/hooks';
import { routes } from '@endaoment-frontend/routes';
import type { PortfolioCategory, UUID } from '@endaoment-frontend/types';
import { ArrowIcon } from '@endaoment-frontend/ui/icons';
import type { CardProps } from '@endaoment-frontend/ui/shared';
import { Button, Card, CardDotsBackground, SwiperButtons } from '@endaoment-frontend/ui/shared';

import styles from './PortfoliosSection.module.scss';

export const PortfoliosSection = () => {
  const { data: portfolioCategories = [] } = GetPortfolioCategories.useQuery([], { staleTime: Infinity });

  const { isMobile } = useIsMobile();

  return (
    <section className={styles['container']}>
      <div>
        <div>
          <h2>Portfolios</h2>
          <p>Deploy donations directly to board-approved crypto assets & DeFi protocols to grow your impact.</p>
        </div>
        <Swiper
          slidesPerView={isMobile ? 1 : 2}
          spaceBetween={24}
          loop
          wrapperTag='ul'
          centeredSlides
          initialSlide={1}
          className={styles['carousel']}>
          {(portfolioCategories ?? []).map(category => {
            return (
              <SwiperSlide key={category.id} tag='li' className={styles['carousel-item']}>
                <CategoryCard category={category} />
              </SwiperSlide>
            );
          })}
          <SwiperSlide key='Private Wealth Management' tag='li' className={styles['carousel-item']}>
            <CategoryCard
              key='Private Wealth Management'
              as={Link}
              href='https://endaoment.typeform.com/private-wealth'
              target='_blank'
              category={{
                id: '0' as UUID,
                name: 'Private Wealth Management',
                description: 'Select from our curated library of actively managed investment portfolios.',
                colorRgb: '#696f8c',
                logoUrl:
                  'https://storage.googleapis.com/endaoment-static/portfolio-categories/private-wealth-management.svg',
              }}
            />
          </SwiperSlide>
          <SwiperButtons outside />
        </Swiper>
        <Button as={Link} href={routes.app.portfolios()}>
          All Portfolios
          <ArrowIcon />
        </Button>
      </div>
    </section>
  );
};

const CategoryCard = <Tag extends As>({ category, ...props }: CardProps<Tag> & { category: PortfolioCategory }) => {
  const style = {
    '--card-color': category.colorRgb,
    '--card-color-half': `${category.colorRgb}7F`,
    '--card-color-quarter': `${category.colorRgb}3F`,
    '--card-color-tenth': `${category.colorRgb}19`,
    '--card-color-eight': `${category.colorRgb}23`,
    '--shadow-color': `0px 18px 26px 0px var(--card-color-tenth)`,
    ...props.style,
  };
  return (
    <Tilt perspective={1000} tiltMaxAngleX={5} tiltMaxAngleY={5}>
      <Card
        className={styles['portfolio-categories-card']}
        as={Link}
        href={routes.app.portfolios({ categoryIds: [category.id], view: 'portfolios' })}
        data-color={category.colorRgb}
        style={style}
        {...props}>
        <Image src={category.logoUrl} alt={category.name} width={60} height={60} />
        <h3>{category.name}</h3>
        <p>{category.description}</p>
        <Button filled float={false} size='small' className={clsx(styles['portfolio-categories-card-button'])}>
          See Options <ArrowIcon color='white' />
        </Button>
        <CardDotsBackground className={styles['card-dots-background']} />
      </Card>
    </Tilt>
  );
};
